
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CommonDateType } from "@/core/directive/type/common";

interface Filter {
  tracking_no: string;
  declaration_import_no: string;
  import_date_start: CommonDateType;
  import_date_end: CommonDateType;
  customs_declaration_date_start: CommonDateType;
  customs_declaration_date_end: CommonDateType;
  registration_number: string;
}

export default defineComponent({
  name: "filter-import-declaration-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      tracking_no: "",
      declaration_import_no: "",
      import_date_start: "",
      import_date_end: "",
      customs_declaration_date_start: "",
      customs_declaration_date_end: "",
      registration_number: "",
    });

    const options = ref({
      // import_date: [],
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
    };
  },
});
