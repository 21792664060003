
import { defineComponent, ref, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiShipments } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-import-declaration",
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addImportDeclaration = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const formData = ref({
      customs_declaration_no: "",
      consignee_name: "",
      declaration_category: "",
      import_date: null,
      customs_declaration_date: null,
      registration_number: "",
      exporter_name: "",
      delivery_type: "",
      carrier_name_and_flight_number: "",
      awb_number: "",
      warehouse_name: "",
      contract_number: "",
      country_of_trade: "",
      country_of_export: "",
      transit_port: "",
      import_port: "",
      packing_materials: "",
      quantity: "",
      gross_weight: "",
      net_weight: "",
      trade_terms: "",
      shipping_fee: "",
      insurance_fee: "",
      other_fees: "",
    });

    const options = ref({
      delivery_type: [],
      country_of_trade: [],
      country_of_export: [],
      transit_port: [],
      import_port: [],
      packing_materials: [],
      trade_terms: [],
    });

    const rules = ref({
      // status: [
      //   {
      //     required: true,
      //     message: "Status is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const getTaggingData = () => {
      ApiBase.getTaggingData({
        short_key: ["warehouse_type", "warehouse_taggings"],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            // options.value.type = data.data.warehouse_type.items;
            // options.value.taggings = data.data.warehouse_taggings.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiShipments.addShipment(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addImportDeclaration.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addImportDeclaration.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      addImportDeclaration,
      submitButton,
      options,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
